//@ts-nocheck
import { useEffect, useRef, useState } from 'react';
import './App.css';
import { Spinner } from './components/Spinner';

const DEFAULT_TEXT = 'Hi Alan, what the meaning of life?';

function App() {
  const [audioSrc, setAudioSrc] = useState('');
  const [prompt, setPrompt] = useState(DEFAULT_TEXT);
  const [loading, setLoading] = useState(false);
  const [language, setLanguage] = useState('EN');
  const [option, setOption] = useState('Alan');
  const [isRecognizing, setIsRecognizing] = useState(false);
  const [sessionSeed, setSessionSeed] = useState(Math.random());
  const assets = useRef([]);
  const audioRef = useRef(null);
  const recognitionRef = useRef(null);
  const idleTimerRef = useRef(null);
  const isVoiceRecognitionEnabledRef = useRef(true);
  const [isVoiceRecognitionEnabled, setIsVoiceRecognitionEnabled] = useState(isVoiceRecognitionEnabledRef.current);

  const resetSessionSeed = () => {
    console.log('reset seed');
    setSessionSeed(Math.random());
  };

  useEffect(() => {
    idleTimerRef.current = setTimeout(resetSessionSeed, 60000);
    return () => clearTimeout(idleTimerRef.current);
  }, [prompt, audioSrc]);

  useEffect(() => {
    if (recognitionRef.current) {
      console.log('language changed', language)
      if (language == 'ID') {
        recognitionRef.current.lang = 'id-ID';
      } else {
        recognitionRef.current.lang = language === 'EN' ? 'en-US' : 'ru-RU';
      } 
    }
  }, [language]);

  useEffect(() => {
    if (isRecognizing && recognitionRef && recognitionRef.current) {
      recognitionRef.current?.stop();
    }
  }, [isRecognizing, language, option, sessionSeed]);

  const toggleRecognition = () => {
    setIsRecognizing(!isRecognizing);
  };
  const [shouldSayPrompt, setShouldSayPrompt] = useState(false);

  useEffect(() => {
    const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
    if (SpeechRecognition) {
      if (!recognitionRef.current) {
        startMicVolumeListener();
        recognitionRef.current = new SpeechRecognition();
        recognitionRef.current.continuous = true;
        recognitionRef.current.interimResults = true;
        if (language == 'ID') {
          recognitionRef.current.lang = 'id-ID';
        } else {
          recognitionRef.current.lang = language === 'EN' ? 'en-US' : 'ru-RU';
        } 
        
        recognitionRef.current.onend = () => {
          if (isRecognizing) {
            recognitionRef.current.start();
          }
        };

        recognitionRef.current.onerror = (event) => {
          console.log(event);
          try {
            //recognitionRef.current.abort();
            //recognitionRef.current.start();
          } catch (error) {
            //
          }
        };

        recognitionRef.current.onresult = (event) => {
          // console.log(event.results[0][0].confidence);
          const lastResult = event.results[event.results.length - 1];
          if (lastResult.isFinal) {
            setTimeout(() => {
              const text = lastResult[0].transcript;
              console.log('Confidence: ' + event.results[0][0].confidence);
              console.log(option, language, sessionSeed);
              setPrompt(text);
              setShouldSayPrompt(true);
              clearTimeout(idleTimerRef.current);
              idleTimerRef.current = setTimeout(resetSessionSeed, 60000);
            }, 500);
          }
        };
      }

      if (language == 'ID') {
        recognitionRef.current.lang = 'id-ID';
      } else {
        recognitionRef.current.lang = language === 'EN' ? 'en-US' : 'ru-RU';
      }
      console.log(recognitionRef.current);
      if (isRecognizing) {
        try {
          recognitionRef.current.start();
        } catch (error) {
          //
        }
      } else {
        recognitionRef.current.stop();
      }
    }
  }, [isRecognizing, language, option, sessionSeed]);

  useEffect(() => {
    if (shouldSayPrompt) {
      sayPrompt();
      setShouldSayPrompt(false); // Reset the flag after calling sayPrompt
    }
  }, [shouldSayPrompt]);

  useEffect(() => {
    if (option === 'Alan') {
      setPrompt('Hi Alan, what the meaning of life?');
    } else {
      setPrompt('');
    }
  }, [option]);

  let stream: MediaStream | null;
  let audioContext: AudioContext | null;

  // New function to toggle voice recognition enablement
  const toggleVoiceRecognitionEnablement = () => {
    isVoiceRecognitionEnabledRef.current = !isVoiceRecognitionEnabledRef.current;
    setIsVoiceRecognitionEnabled(isVoiceRecognitionEnabledRef.current);

    // If disabling, also stop recognition if it's currently active
    if (isVoiceRecognitionEnabledRef.current && isRecognizing) {
      recognitionRef.current?.stop();
      setIsRecognizing(false);
    }
  };

  async function startMicVolumeListener() {

    if (!isVoiceRecognitionEnabledRef.current) {
      return;
    }
    
    stopMicVolumeListener();


    navigator.mediaDevices.getUserMedia({
      audio: true,
      video: false,
    })
      .then((_stream) => {
        stream = _stream;
        audioContext = new AudioContext();

        // audioContext.audioWorklet.addModule('mic-volume-processor.js');

        const analyser = audioContext.createAnalyser();
        const microphone = audioContext.createMediaStreamSource(stream);
        const scriptProcessor = audioContext.createScriptProcessor(2048, 1, 1);
        // const micVolumeNode = new AudioWorkletNode(audioContext, 'mic-volume-processor');

        analyser.smoothingTimeConstant = 0.8;
        analyser.fftSize = 1024;
    
        microphone.connect(analyser);
        analyser.connect(scriptProcessor);
        //micVolumeNode.connect(audioContext.destination);
        //micVolumeNode.port.onmessage = (event) => {
        //  onVolume(event.data);
        //};

        scriptProcessor.connect(audioContext.destination);
        scriptProcessor.onaudioprocess = async function() {
        
          const array = new Uint8Array(analyser.frequencyBinCount);
          // console.log(array);
          analyser.getByteFrequencyData(array);
          const arraySum = array.reduce((a, value) => a + value, 0);
          // console.log(arraySum);
          const average = arraySum / array.length;
          const micVolume = Math.round(average);
          // console.log(micVolume, assets.current);
          // console.log(micVolume);
          if (!isVoiceRecognitionEnabledRef.current) {
            // console.log('disabled');
            return;
          }
          if (micVolume > 25) {
            setIsRecognizing(true);
            clearTimeout(idleTimerRef.current);
            idleTimerRef.current = setTimeout(resetSessionSeed, 60000);
            console.log('STOP');
            setLoading(false);
            const audioElement = audioRef.current;
            audioElement.pause();
            
          }
        };
      })
      .catch(function(err) {
        console.error(err);
        onError(err?.name || err?.message || err);
      });
  }

  function stopMicVolumeListener() {
    if (audioContext) {
      audioContext.close();
      audioContext = null;
    }

    if (stream) {
      const tracks = stream.getTracks() || [];
      for (const track of tracks) {
        track.stop();
      }
      stream = null;
    }
  }

  const sayPrompt = async () => {
    console.log({ prompt, option, language, sessionSeed});

    if (!audioRef.current) return;

    try {
      const searchParams = new URLSearchParams({ prompt, option, language, sessionSeed});
      console.log(searchParams);
      // setAudioSrc(`/say-prompt?${searchParams.toString()}`);
      setLoading(true);

      //audioElement.load();
      setIsRecognizing(false);
      const audioElement = audioRef.current;
      audioElement.pause();
      audioElement.currentTime = 0;

      setAudioSrc(`/say-prompt?${searchParams.toString()}`);
      setLoading(true);

      audioElement.load();

      const playAudio = () => {
        setIsRecognizing(false);
        audioElement.play().catch(e => {
          console.error('Playback failed:', e);
        }).finally(() => {
          console.log('Playback finished');
        });
        setLoading(false);
      };

      audioElement.addEventListener('loadeddata', playAudio);
      audioElement.addEventListener('ended', function() {
        console.log('finished');
        setIsRecognizing(true);
      });
      // audioElement.addEventListener('error', onError);

      return () => {
        audioElement.removeEventListener('loadeddata', playAudio);
        // audioElement.removeEventListener('error', onError);
      };
    } catch (error) {
      console.log(error);
    }
  };

  const handleLanguageChange = (e) => {
    setLanguage(e.target.value);
    console.log('language changed', e.target.value)
    if (isRecognizing) {
      recognitionRef.current?.stop();
    }
  };

  const handleOptionChange = (e) => {
    setOption(e.target.value);
    if (isRecognizing) {
      recognitionRef.current?.stop();
    }
  };

  return (
    <>
      <h1 className="pb-8 max-sm:text-2xl font-bold">Inite Alpha Example</h1>

      <div className="buttons-container">

        <button onClick={toggleRecognition}>
          {isRecognizing ? 'Stop' : 'Start'} Speech Recognition
        </button>
        
        <button onClick={toggleVoiceRecognitionEnablement}>
          {isVoiceRecognitionEnabled ? 'Disable' : 'Enable'} Interuption
        </button>

      </div>

      <select value={language} onChange={handleLanguageChange}>
        <option value="EN">EN</option>
        <option value="RU">RU</option>
        <option value="ID">ID</option>
      </select>

      <select value={option} onChange={handleOptionChange}>
        <option value="Alan">Alan Watts</option>
	<option value="Aurora">Aurora</option>
      </select>

      <textarea
        className="w-full h-32 bg-inherit resize-none border border-neutral-200 dark:border-neutral-700 rounded-lg p-4 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:border-transparent text-lg"
        value={prompt}
        onChange={(e) => setPrompt(e.target.value)}
      />
{/* 
       <textarea
        className="w-full h-32 mt-4 bg-gray-100 resize-none border border-neutral-300 dark:border-neutral-600 rounded-lg p-4 text-lg"
        value={returnedText}
        disabled
      /> */}

      <button
        className="disabled:text-neutral-500 mt-6 mb-8 py-4 px-8 bg-green-600 text-white font-bold text-xl enabled:hover:bg-green-400 transition-all"
        onClick={() => sayPrompt()}
        disabled={loading}
      >
        <div className="inline-flex h-full w-full items-center justify-center">
          {loading && <Spinner />} <span className="bold">Start</span>
        </div>
      </button>

      <audio className="w-full" ref={audioRef} src={audioSrc} controls autoplay={true} playsinline={true} />

      {/* <div className="mt-4">
      <label htmlFor="sessionSeed">Session Seed: </label>
      <input 
        id="sessionSeed" 
        type="text" 
        value={sessionSeed}
        className="border border-neutral-200 dark:border-neutral-700 rounded-lg p-2"
        disabled 
      />
    </div> */}
    </>
  );
}

export default App;
